<template>
  <div>
    <vs-row class="heading-row">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="heading">تقرير مندوب</h1>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3">
        <p>تقرير السيد\ة</p>
      </vs-col>
      <vs-col vs-w="3">
        <h6>{{ user.user.name }} </h6>
      </vs-col>

      <vs-col vs-w="3"  vs-justify="end">
        <p>من التاريخ</p>
        <p>الى التاريخ</p>
      </vs-col>
      <vs-col vs-w="3"  vs-justify="end">
        <h6>{{ user.from }}</h6>
        <h6>{{ user.to }}</h6>
      </vs-col>
    </vs-row>
    <vs-row class="order-items justify-content-end">
      <vs-col vs-align="center" vs-w="12">
        <vs-table hoverFlat :data="data.Orders" class="w-full order-table">
          <template slot="thead">
           <vs-th>رقم الطلبية</vs-th>
            <vs-th>تاريخ الإنشاء</vs-th>
            <vs-th>تاريخ التوصيل</vs-th>
            <vs-th>سعر التوصيل</vs-th>
            <vs-th>إسم المتجر</vs-th>
            <vs-th>الإجمالي</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="(tr, index) in data" :key="index">
              <vs-td class="span">{{ tr.id }}</vs-td>
              <vs-td class="span">{{ formatDateHour(tr.created_at) }}</vs-td>
              <vs-td class="span">{{ tr.delivery_date }}</vs-td>
              <vs-td class="span">{{ tr.delivery_price }}</vs-td>
              <vs-td class="span">{{
                tr.store ? tr.store.name : "بوتيكيت - Boutiquette"
              }}</vs-td>
              <vs-td class="span">{{ tr.total_amount }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Object,
    },
    user: {
      required: true,
    },

  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/invoice.scss";
</style>
<style lang="scss">
@import "@/assets/scss/print.scss";
</style>
